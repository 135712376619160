import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


const actions = {}
const mutations = {
    user_login: (state, result) => {
        let user = result.user
        let token = result.token
        // 把登录的用户的名保存到localStorage中，防止页面刷新，导致vuex重新启动，用户名就成为初始值（初始值为空）的情况
        localStorage.setItem('userInfo', JSON.stringify(user))
        localStorage.setItem('role', user.role)
        localStorage.setItem('userId', user.id)
        localStorage.setItem('token', token);
    }, user_loginOut: (state, user) => {
        state.userInfo = {}
        localStorage.removeItem('userInfo')
        localStorage.removeItem('role')
        localStorage.removeItem('userId')
        localStorage.removeItem('token');
    },


    //设置页签状态：当前激活或未激活
    SET_TAB_VISIBILITY_CHANGE_STATUS: (state, status) => {
        state.tabVisibilityChangeStatus = status
    },

}
const state = {
    userInfo: {},
    tabVisibilityChangeStatus: JSON.parse(sessionStorage.getItem("tabVisibilityChangeStatus")),
}
// getters 只会依赖 state 中的成员去更新
const getters = {
    user: (state) => state.userInfo,
    tabVisibilityChangeStatus: state => state.tabVisibilityChangeStatus,
}

const tabStatus = {
    mutations: {   //设置页签状态：当前激活或未激活
        SET_TAB_VISIBILITY_CHANGE_STATUS: (state, status) => {
            sessionStorage.setItem('tabVisibilityChangeStatus', JSON.stringify(status))
            state.tabVisibilityChangeStatus = status
        },
    },
    actions: {}
}


const store = new Vuex.Store({
    actions, mutations, state, getters
})
export {store, tabStatus}

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/',
        name: 'globalLayout',
        component: () => import(/* webpackChunkName: "about" */ '../components/GlobalLayout'),
        children:[
            {
                path: '/home',
                name: 'home',
                component: () => import(/* webpackChunkName: "about" */ '../views/oldHomeView.vue')
            },{
                path: '/account',
                name: 'account',
                component: () => import(/* webpackChunkName: "about" */ '../views/AccountView.vue')
            },{
                path: '/record',
                name: 'record',
                component: () => import(/* webpackChunkName: "about" */ '../views/RecordView.vue')
            },{
                path: '/cover',
                name: 'cover',
                component: () => import(/* webpackChunkName: "about" */ '../views/CoverView.vue')
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
    console.log('navigation-guards');
    if (to.name == 'login'){
        next();
    }
    let routers = ['globalLayout','home','cover','account','record']
    if (routers.indexOf(to.name)>=0){
        let userId = localStorage.getItem("userId");
        if (userId){
            next()
        }else {
            router.push({ name: 'login' });
        }
    }
});

export default router

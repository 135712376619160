import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {store} from './store'


import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import Storage from 'vue-ls';
import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
    ak: 'iWnDaUHkpIele6u8nRGoPYVOZa4qei1v'
})
Vue.use(Antd);
Vue.use(Storage)
Vue.config.productionTip = false
// 路由跳转
Vue.prototype.$goRoute = function (index) {
    this.$router.push(index)
}
new Vue({
    router,
    store,
    render: h => h(App),
    el: '#app',
    components: {App},
    template: '<App/>',
}).$mount('#app')
